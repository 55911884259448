import * as styles from './Hero.module.css';

import { Link } from 'gatsby';
import React from 'react';

const getStyle = (length, i) => {
  return {
    animation: `${styles.imageAnimation} ${length}s linear infinite  forwards ${
      i * 12
    }s`,
    zIndex: -i,
  };
};

const Text = ({ text }) => {
  const slides = text.map((el, i) => {
    const style = getStyle(text.length * 12, i);

    return (
      <li key={i} style={style}>
        {i === 0 ? (
          <h1 style={style}>{el.H1}</h1>
        ) : (
          <h2 style={style}>{el.H1}</h2>
        )}
        <p style={style}>{el.Text}</p>
        <Link style={style} to={`${el.CTALink}`}>
          {el.CTAText}
        </Link>
      </li>
    );
  });

  return (
    <>
      <ul className={styles.SlideshowText}>{slides}</ul>
    </>
  );
};

export default Text;
