import * as styles from './Hero.module.css';

import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

const Images = ({ images }) => {
  const slides = images.map((image, i) => {
    return (
      <li key={i}>
        <span
          style={{
            animationDuration: `${images.length * 12}s`,
            animationDelay: `${i * 12}s`,
          }}>
          <GatsbyImage
            image={image}
            loading='eager'
            style={{ width: '100%', height: '100vh' }}
          />
        </span>
      </li>
    );
  });
  return (
    <>
      <ul className={styles.Slideshow}>{slides}</ul>
    </>
  );
};

export default Images;
