import * as styles from './Reviews.module.css';

import React, { useCallback, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import QuoteLeft from '../../images/quote-left.svg';
import QuoteRight from '../../images/quote-right.svg';
import Stars from '../../images/stars.svg';

const Reviews = (props) => {
  const data = useStaticQuery(graphql`
    query ReviewQuery {
      allStrapiReviews {
        nodes {
          Content
          Name
          Date
          Stars
        }
      }
    }
  `);

  const [index, setIndex] = useState(0);

  const reviews = data.allStrapiReviews.nodes;

  const handleChange = useCallback(
    (e) => {
      if (e === '+') {
        if (index === reviews.length || index === reviews.length - 1) {
          setIndex(0);
        } else {
          setIndex(+index + 1);
        }
      } else if (e === '-') {
        if (index === 0 || index <= 0) {
          setIndex(reviews.length - 1);
        } else {
          setIndex(+index - 1);
        }
      }
    },
    [index, reviews.length]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      handleChange('+');
    }, 8000);
    return () => {
      clearTimeout(timer);
    };
  }, [index, handleChange]);

  return (
    <div className={styles.Reviews}>
      <div className={styles.Stars}>
        <Stars />
      </div>
      <div className={styles.Review}>
        <div onClick={() => handleChange('-')}>
          <QuoteLeft />
        </div>

        <div className={styles.Text}>
          <blockquote>{reviews[index] && reviews[index].Content}</blockquote>
        </div>
        <div onClick={() => handleChange('+')}>
          <QuoteRight />
        </div>
      </div>
      <p className={styles.Name}>
        <strong>- {reviews[index] && reviews[index].Name}</strong>
      </p>

     
    </div>
  );
};

export default Reviews;
