import * as styles from './Hero.module.css';

import { graphql, useStaticQuery } from 'gatsby';

import Images from './Images';
import React from 'react';
import Text from './Text';
import { getImage } from 'gatsby-plugin-image';

const Hero = (props) => {
  // needs onload animation or 1st slide looks choppy
  const { strapiHeroSlideshow: hero } = useStaticQuery(graphql`
    query heroQuery {
      strapiHeroSlideshow {
        CTA
        CTALink
        Slides {
          H1
          Text
          CTAText
          CTALink
          Background {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `);

  const images = hero.Slides.map((el, i) => {
    if(el.Background){
      return getImage(el.Background.localFile);
    }
  });

  const text = hero.Slides.map((el, i) => {
    const arr = { ...el };
    delete arr.Background;
    return arr;
  });

  return (
    <div className={styles.Hero}>
      <Images images={images} />
      <div className={styles.Content}>
        <Text text={text} />
      </div>
    </div>
  );
};

export default Hero;
