import { graphql, useStaticQuery } from 'gatsby';

import Hero from '../components/UI/Hero/Hero';
import Layout from '../containers/layout';
import React from 'react';
import Reviews from '../components/Reviews/Reviews';

const Index = ({ location }) => {
  const indexPageData = useStaticQuery(graphql`
    query indexPageQuery {
      strapiStaticPages(Title: { eq: "HomePage" }) {
        Title
        SEO {
          MetaTitle
          MetaDescription
          Keywords
          PreviewImage {
            formats {
              small {
                url
                width
                height
              }
            }
          }
        }
      }
    }
  `);

  const seoData = indexPageData.strapiStaticPages.SEO;

  return (
    <Layout index location={location} seoData={seoData}>
      <section>
        <Hero></Hero>
      </section>
      <section>
        <Reviews />
      </section>
    </Layout>
  );
};

export default Index;
